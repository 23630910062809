import React from 'react';
import { Box, LoadingPlaceholder } from '@grafana/ui';
import { PluginPage } from '@grafana/runtime';

export default function FallbackLoadingPlaceholder() {
  return (
    // Styles for the placeholder are matching the Grafana Loader.tsx component, which renders when loading a plugin.
    // This helps to keep loading states from flickering
    <Box
      marginTop={2}
      paddingTop={10}
      direction="column"
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <LoadingPlaceholder text="Loading..." />
    </Box>
  );
}
